div.tree-ance {
	.account__avatar {
		display: none;
	}
	.display-name {
		display: none;
	}
}
.tree {
	a.status__display-name {
		>span {
			>bdi {
				>strong {
					animation: none;
				}
			}
		}
	}
	a {
		>div {
			>div.account__avatar {
				animation: none;
			}
		}
	}
}
.tree-ance {
	background: linear-gradient(-15deg, #282C3710,90%, #197171, 95%, #0DA454);
	>div.status {
		padding-left: 18px;
		>div.deep__number {
			text-align: left;
		}
	}
}
.tree-desc {
  background: linear-gradient(15deg, #282C3710,90%, #197171, 95%, #0da454);
	>div.status {
		>div.deep__number {
			text-align: right;
		}
	}
}
svg.tree-svg {
	.node {
		circle {
			fill: #F3F3FF;
			stroke: #2593B8;
			stroke-width: 1.5px;
		}
		text {
			font-size: 11px;
			background-color: #444;
			fill: #F4F4F4;
			text-shadow: 0 1px 4px black;
		}
		cursor: pointer;
	}
	path.link {
		fill: none;
		stroke: #2593B8;
		stroke-width: 1.5px;
	}
}

