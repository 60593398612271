.column {
	flex: 1 0 auto;
}

.pinned-info {
  position: relative;
	opacity: 0.85;
  font-size: 15px;
  padding: 10px 20px;
  a {
    color: #3fadfd;
  }
}

.pinned-info__icon {
  .fa {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }
}

div {
  &.status__info {
    & > a {
      &.status__display-name {
        display: inline-block;
      }
    }
  }
}

.gifv {
  & > video {
    width: 100%;
    max-height: 100%;
  }
}

.columns-area--mobile {
  .getting-started__trends {
    display: block;
    .trends__item {
      display: flex;
    }
  }
}

.status__quote__wrapper {
  margin-top:16px;
  border-left: 5px solid #dbdbdb80;
  background: #dbdbdb40;
 
  .status {
      padding-left:40px;

      .status__action-bar {
        display: none;
      }
      .status__avatar {
	      transform: scale(0.5);
	      transform-origin: 0% 0%;
      }
  }
}

.status__tree__quote__wrapper {
  padding: 10px 5px;
  background: #dbdbdb40;
  cursor: pointer;
} 

@keyframes like {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes unlike {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(240deg);
  }
  80% {
    transform: rotateY(140deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.no-reduce-motion .icon-button.star-icon {
  &.activate {
    & > .fa-heart {
      animation: like 1s linear;
    }
  }
}

.no-reduce-motion .icon-button.star-icon {
  &.deactivate {
    & > .fa-heart {
      animation: unlike 1s linear;
    }
  }
}
