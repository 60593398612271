.comments-timeline {
  max-height: 160px;
  min-width: 60%;
  max-width: 100%;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(#1a1a1a,transparent);
  mask-image: linear-gradient(#1a1a1a,transparent);
  transform: scale(0.85);
  transform-origin: 100% 0%;
  margin-bottom: -32px;
  margin-right:8px;
  position: relative;
  z-index:9;
  &:hover {
    max-height: 60vh;
    overflow-y: auto;
    -webkit-mask-image: none;
    mask-image: none;
    z-index:99;
    background: $tc-background;
    box-shadow: $primary-text-color 3.2px 3.2px 8px;
  }
  &:active {
    max-height: 60vh;
    overflow-y: auto;
    -webkit-mask-image: none;
    mask-image: none;
    z-index:99;
    background: $tc-background;
    box-shadow: $primary-text-color 3.2px 3.2px 8px;
  }
  & .comments-timeline-2 {
    margin-left:42px;
  }
}

.comments-timeline__wrapper {
  height: 135px;
}
